<!-- <section class="blink-sec">
    <p>Vsolv - CRM</p>
  </section> -->
<section class="spinner-sec">
  <div class="overlay"></div>
  <div class="spinner-content">
    <p class="vsolv-text">Vsolv</p>
    <p class="crm-text"><span>N</span><span>A</span><span>C</span></p>
  </div>
</section>
