<div style="margin-left: 40px;">
    <form [formGroup]="tqQueryForm" (ngSubmit)="tqQuery()">
        <mat-form-field style="width: 500px;">
            <textarea matInput placeholder="Ex. this is example..." formControlName="key"></textarea>
        </mat-form-field>
        <button type="submit" class="btn btn-outline-success">Exe</button>
    </form>
</div>

<div>
    <div class="table-responsive">
        <table class="table" style="width: 99%;margin-top: 10px;">
            <thead class="table_header" style="text-align: left;">
                <tr>
                    <td *ngFor=" let key of keyValue">
                        {{key}}
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor=" let res of tqQueryData">
                    <td *ngFor=" let key of keyValue">
                        {{res[key] === null ? '-' : res[key]}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>