import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  TokenRefreshintervalId: any;
  isLoggedin = false;
  // isWorkGroup=false;
    entity_Name = '';
    public entity_name =new BehaviorSubject<any>('');
    isSideNav = false;
  Loginname = '';
  Memofrom = '';
  loginUserId = '';
  loginEmpId = '';
  MyModuleName = '';
  transactionList = [];
  private navMenuUrl: string = '/leads-data';
  getLeadsDataUrl(): string {
    return this.navMenuUrl;
  }
  masterList = [];
  // get_userlocation:any;

  menuUrlData: Array<any>;
  titleUrl: any;
  public forwardMessage = new BehaviorSubject<string>('');
  public summaryData = new BehaviorSubject<string>('');
  public fetchData = new BehaviorSubject<string>('')
  public imageValue = new BehaviorSubject<any>('')
  public get_userlocation = new BehaviorSubject<any>(' ');
  public fetchDataa = new BehaviorSubject<string>('')
  public deptEditValue = new BehaviorSubject<string>('');
  public categoryEditValue = new BehaviorSubject<string>('');
  public BranchbankEditValue = new BehaviorSubject<string>('');
  public subCategoryEditValue = new BehaviorSubject<string>('');
  public employeeView = new BehaviorSubject<string>('');
  public departmentView = new BehaviorSubject<string>('');
  public empView = new BehaviorSubject<string>(' ');
  public priorityEditValue = new BehaviorSubject<string>(' ');
  public ionName = new BehaviorSubject<string>('');
  public branchreadonly = new BehaviorSubject<number>(0);
  // public menuUrlData = new BehaviorSubject<any>('');
  // public titleUrls = new BehaviorSubject<any>('');
  public subCategoryID = new BehaviorSubject<any>('');
  public memoView = new BehaviorSubject<any>('');
  public memoViews = new BehaviorSubject<any>('');
  public submodulesfa = new BehaviorSubject<any>('')
  public submodulestneb = new BehaviorSubject<any>('')
  public submodulessms = new BehaviorSubject<any>('');
  public submodulessmsmaster = new BehaviorSubject<any>('');
  public commonsummary = new BehaviorSubject<any>('');

  public branchView = new BehaviorSubject<any>('');

  public co_do_consumerno = new BehaviorSubject<any>('');
  public vendorViewHeaderName = new BehaviorSubject<string>(' ');

  public vendorView = new BehaviorSubject<string>(' ');
  public submoduledata = new BehaviorSubject<string>(' ');
  public lead_Id = new BehaviorSubject<string>('');
  public toggle = new BehaviorSubject<string>('');
  public sourceId = new BehaviorSubject<string>('')
  public campaigncreation = new BehaviorSubject<string>('')
  public urlModule = new BehaviorSubject<string>('');
  public reloadUrl = new BehaviorSubject<string>('');
  public reloadUrl2 = new BehaviorSubject<string>('');
  public myModuleUrl = new BehaviorSubject<string>('');
  public isWorkGroup = new BehaviorSubject<string>('');
  public customList = new BehaviorSubject<string>('');
  public productSingleGetId = new BehaviorSubject<string>('');
  public productImageLogo = new BehaviorSubject<string>('');


  private Sidnave: BehaviorSubject<string>;
  sidenavhide$: Observable<string>;
  userlocation: boolean;
  prapproverblk: any;

  private submoduleDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  submoduleData$ = this.submoduleDataSubject.asObservable();

  setSubmoduleData(submoduleData: any): void {
    this.submoduleDataSubject.next(submoduleData);
  }
  setnav(language: string): void {
    // console.log("sidenav",language)
    this.Sidnave.next(language);
  }
  constructor() {
    // Initialize the selected language subject with default language
    this.Sidnave = new BehaviorSubject<string>('en');
    this.sidenavhide$ = this.Sidnave.asObservable();
  }

  // constructor() { }
}




