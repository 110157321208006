import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from "rxjs";
import { catchError, map, retry } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/service/notification.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';

// const produrl = environment.apiURLS
const produrl = environment.apiURL


@Injectable({
  providedIn: 'root'
})
export class LeadsmainService {

  constructor(private http: HttpClient, private idle: Idle) { }

  idleState = 'Not started.';
  timedOut = false;
  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  public leadmasterdata(page, params): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.get<any>(produrl + "prodserv/lead_entry?page=" + page + params, { 'headers': headers })
    // return this.http.get<any>(produrl + "prodserv/lead_info/1", { 'headers': headers })


  }

  public uploadfileheaders(file): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token

    if (file == undefined) {
      file = ''
      console.log("Error in file")
    }
    let formData = new FormData();

    formData.append('file', file)
    const headers = { 'Authorization': 'Token ' + token }

    return this.http.post<any>(produrl + "leadserv/file_header",  formData, { 'headers': headers })
   


  }

  public fileuploads(creatlist, file): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token

    if (file == undefined) {
      file = ''
      console.log("Error in file")
    }

    let json = Object.assign({}, creatlist)
    let formData = new FormData();
    formData.append('data', JSON.stringify(json));
    formData.append('file', file)
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.post<any>(produrl + "prodserv/lead_entry", formData, { 'headers': headers })



  }


  public fileuploadsmap(creatlist, file): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token

    if (file == undefined) {
      file = ''
      console.log("Error in file")
    }

    let formData = new FormData();
    // formData.append('data', JSON.stringify(json));
    formData.append('data', creatlist)
    formData.append('file', file)
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.post<any>(produrl + "leadserv/lead_entry", formData, { 'headers': headers })



  }

  public tableheaderdata(): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.get<any>(produrl + "prodserv/table_header", { 'headers': headers })


  }


  public getsources(): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.get<any>(produrl + "prodserv/source", { 'headers': headers })
  }

  public getleadsview(id): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.get<any>(produrl + "prodserv/lead_info/" + id, { 'headers': headers })
  }
  public getleadsviewleads(id): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.get<any>(produrl + "newprodserv/fetch_modulewise/" + id+'?type=1' ,{ 'headers': headers })
  }


  public createnewtemplate(creatlist): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token



    // let json = Object.assign({}, creatlist)
    // let formData = new FormData();
    // formData.append('data', JSON.stringify(json));
    // formData.append('file', file)
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.post<any>(produrl + "prodserv/header_template", creatlist, { 'headers': headers })


  }

  public gettemplates(): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.get<any>(produrl + "prodserv/header_template", { 'headers': headers })
  }

  //lead_update_records

  public getupdaterecords(id): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.get<any>(produrl + "prodserv/lead_update_records/" + id, { 'headers': headers })
  }
  public gettemplatesummary(page, search): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.get<any>(produrl + "prodserv/header_template?action=summary&page=" + page + search, { 'headers': headers })
  }

  public deletetemplates(id): Observable<any> {
    this.reset();
    let comments = '';
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    // const body = JSON.stringify(comments)
    const headers = { 'Authorization': 'Token ' + token }
    const options = {
      headers: headers,
      body: { 'comment': comments },
    };

    return this.http.delete<any>(produrl + "prodserv/header_template?id=" + id, { 'headers': headers })
  }
  public dropdownvalues(): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.get<any>(produrl + "prodserv/crm_dropdown?dropdown_type=label_type", { 'headers': headers })


  }
  public dropdownRefValues(): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.get<any>(produrl + "prodserv/crm_dropdown?dropdown_type=label_refmodule", { 'headers': headers })


  }
  public addNewLabel(json,type:number=1): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    console.log("docpost", json)
    return this.http.post<any>(produrl +  'custserv/label'+"?type="+type, json, { 'headers': headers })
  }

  public leadfieldupdate(json,type:number=1): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    console.log("docpost", json)
    return this.http.post<any>(produrl +'custserv/label'+"?type="+type, json, { 'headers': headers })
  }

  // public getsummaryLabel(json): Observable<any> {
  //   this.reset();
  //   const getToken = localStorage.getItem("sessionData")
  //   let tokenValue = JSON.parse(getToken);
  //   let token = tokenValue.token
  //   const headers = { 'Authorization': 'Token ' + token }
  //   console.log("docpost", json)
  //   return this.http.get<any>(produrl + "prodserv/lable", json +  { 'headers': headers })
  // }
  public dropdownstatus(): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.get<any>(produrl + "prodserv/crm_dropdown?dropdown_type=template", { 'headers': headers })


  }

  public getsummaryLabel(pageno,type:number=1): Observable<any> {
    this.reset();
    const getToken: any = localStorage.getItem('sessionData')
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.get<any>(produrl + 'custserv/label?page=' + pageno+"&type="+type, { 'headers': headers })
  }

  public getleadsParticular(id): Observable<any> {
    this.reset();
    const getToken: any = localStorage.getItem('sessionData')
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.get<any>(produrl + 'custserv/label?type=fetch&label_id=' + id, { 'headers': headers })
  }
  public sysdefvalues(): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.get<any>(produrl + "prodserv/custom_field_mapping", { 'headers': headers })


  }
  public adddsachart(json): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    console.log("docpost", json)
    return this.http.post<any>(produrl + "prodserv/crm_report", json, { 'headers': headers })
  }

  public dropdownLabel(type:number=1): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.get<any>(produrl +'custserv/label'+"?type="+type, { 'headers': headers })


  }

  public columndata(): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.get<any>(produrl + "prodserv/custom_field_mapping", { 'headers': headers })


  }

  public leadmasterlabel(): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let json = {}
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.post<any>(produrl + "prodserv/cust_fld", json, { 'headers': headers })
    // return this.http.get<any>(produrl + "prodserv/cust_fld", { 'headers': headers })
    // return this.http.get<any>(produrl + "prodserv/lead_info/1", { 'headers': headers })


  }
  public getFilterCount(json): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    // let json = {}
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.post<any>(produrl + "prodserv/campaign?action=count&api_action=new", json, { 'headers': headers })



  }
  public startCampaign(json): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    // let json = {}
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.post<any>(produrl + "prodserv/campaign?action=campaign&api_action=new", json, { 'headers': headers })



  }
  public fileuploadrefresh(id): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.get<any>(produrl + "leadserv/leadsourcefile_get/"+id, { 'headers': headers })


  }
  public getnotesummary(json,type:number=1): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    console.log("docpost", json)
    return this.http.post<any>(produrl +  'newprodserv/user_notes?type='+type+'&action=fetch', json, { 'headers': headers })
  }
  public addnotesummary(json,type:number=1): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    console.log("docpost", json)
    return this.http.post<any>(produrl +  'newprodserv/user_notes?type='+type, json, { 'headers': headers })
  }
  public acc_update(data): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem('sessionData');
    const tokenValue = JSON.parse(getToken);
    const token = tokenValue.token;
    const headers = new HttpHeaders({
      Authorization: 'Token ' + token,
    });
    return this.http.post<any>(produrl + "custserv/module_config?action=update&type=1",data, { headers });
   
  }
  public deletenotes(json,type:number=1): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    console.log("docpost", json)
    return this.http.post<any>(produrl +  'newprodserv/user_notes?type='+type+'&action=delete', json, { 'headers': headers })
  }
  public task_update(data): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem('sessionData');
    const tokenValue = JSON.parse(getToken);
    const token = tokenValue.token;
    const headers = new HttpHeaders({
      Authorization: 'Token ' + token,
    });
    //leadserv/lead
    return this.http.post<any>(produrl + "newprodserv/task?type=1",data, { headers });
    // return this.http.post<any>(memoUrl + 'leadserv/lead_summary', { headers });
  }
  public template_preview(data): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem('sessionData');
    const tokenValue = JSON.parse(getToken);
    const token = tokenValue.token;
    const headers = new HttpHeaders({
      Authorization: 'Token ' + token,
    });
    //leadserv/lead
    return this.http.post<any>(produrl + "custserv/mail_patch",data, { headers });
    // return this.http.post<any>(memoUrl + 'leadserv/lead_summary', { headers });
  }

  public getcreate(accountid): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem('sessionData');
    const tokenValue = JSON.parse(getToken);
    const token = tokenValue.token;
    const headers = new HttpHeaders({
      Authorization: 'Token ' + token,
    });
    //leadserv/lead
    console.log("accountid",accountid)
    return this.http.get<any>(produrl + "newprodserv/fetch_modulewise/"+ accountid +"?type=3", { headers });
    // return this.http.post<any>(memoUrl + 'leadserv/lead_summary', { headers });
  }
  public mailsend(data,type,id): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem('sessionData');
    const tokenValue = JSON.parse(getToken);
    const token = tokenValue.token;
    const headers = new HttpHeaders({
      Authorization: 'Token ' + token,
    });

    return this.http.post<any>(produrl + "custserv/email_sender"+"?ref_type="+type+"&ref_id="+id,data, { headers });
   
  }
  public workflowsummary(page, search): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.get<any>(produrl + "custserv/workflow?page="+ page + search, { 'headers': headers })
  }
  public workflowsummaryview(id): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.get<any>(produrl + "custserv/workflow/"+ id, { 'headers': headers })
  }
  public workflowdelete(id): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.get<any>(produrl + "custserv/workflow/"+id+"?action=delete", { 'headers': headers })
  }
  public workflowstatus(ruleid,statusid): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.get<any>(produrl + "custserv/workflow/"+ruleid+"?action=status change&status="+statusid, { 'headers': headers })
  }
  public rulesubmit(data): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem('sessionData');
    const tokenValue = JSON.parse(getToken);
    const token = tokenValue.token;
    const headers = new HttpHeaders({
      Authorization: 'Token ' + token,
    });

    return this.http.post<any>(produrl + "custserv/workflow?action=create",data, { headers });
   
  }
  public gettimeline(id,type): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.get<any>(produrl + "newprodserv/timeline?type="+type+"&code="+id, { 'headers': headers })
  }
  public attachmentsubmit(file: File, additionalData: any): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData");
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token;
    if (!file) {
      console.log("Error in file");
    }
    let formData = new FormData();
    formData.append('data', additionalData)
    formData.append('file', file);
    const headers = { 'Authorization': 'Token ' + token };
    return this.http.post<any>(produrl + 'custserv/attachement', formData, { headers });
  }
  public getattachmentsummary(id,type): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.get<any>(produrl + "custserv/attachement?ref_type="+type+"&ref_id="+id, { 'headers': headers })
  }
  public getcustomerview(id): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.get<any>(produrl + "newprodserv/fetch_modulewise/" + id+'?type=7' ,{ 'headers': headers })
  }
  public customerupdate(data): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem('sessionData');
    const tokenValue = JSON.parse(getToken);
    const token = tokenValue.token;
    const headers = new HttpHeaders({
      Authorization: 'Token ' + token,
    });
    return this.http.post<any>(produrl + "custserv/module_config?action=update&type=7",data, { headers });
   
  }
  public calllogget(data,page=1): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem('sessionData');
    const tokenValue = JSON.parse(getToken);
    const token = tokenValue.token;
    const headers = new HttpHeaders({
      Authorization: 'Token ' + token,
    });

    return this.http.post<any>(produrl + "callserv/call_log_entry?action=summary&page="+page,data, { headers });
   
  }
  public ruleupdate(data): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem('sessionData');
    const tokenValue = JSON.parse(getToken);
    const token = tokenValue.token;
    const headers = new HttpHeaders({
      Authorization: 'Token ' + token,
    });

    return this.http.post<any>(produrl + "custserv/workflow?action=update",data, { headers });
   
  }
  public modulesum(): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem('sessionData');
    const tokenValue = JSON.parse(getToken);
    const token = tokenValue.token;
    const headers = new HttpHeaders({
      Authorization: 'Token ' + token,
    });

    return this.http.post<any>(produrl +'reportserv/report_summary?action=module_summary', {}, { headers });
   
  }
  public notificationtiming(): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem("sessionData")
    let tokenValue = JSON.parse(getToken);
    let token = tokenValue.token
    const headers = { 'Authorization': 'Token ' + token }
    return this.http.get<any>(produrl + 'newprodserv/task_notification' ,{ 'headers': headers })
  }
  public reportsubmit(data): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem('sessionData');
    const tokenValue = JSON.parse(getToken);
    const token = tokenValue.token;
    const headers = new HttpHeaders({
      Authorization: 'Token ' + token,
    });

    return this.http.post<any>(produrl +'reportserv/report_summary', data, { headers });
   
  }
  public reportdownload(data): Observable<any> {
    this.reset();
    const getToken = localStorage.getItem('sessionData');
    const tokenValue = JSON.parse(getToken);
    const token = tokenValue.token;
    const headers = new HttpHeaders({
      Authorization: 'Token ' + token,
    });

    return this.http.post<any>(produrl +'reportserv/report_summary?type=download',data, {  headers: headers,
      responseType: 'blob' as 'json'   });
   
  }
  
}
