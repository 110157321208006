import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  SimpleChanges,
  ElementRef,
} from "@angular/core";
import { DataService } from "./service/data.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { SharedService } from "./service/shared.service";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
// import { ShareService } from '../app/atma/share.service';
// import { RemsShareService } from '../app/rems/rems-share.service'
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, FormGroupDirective } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { NotificationService } from "./service/notification.service";
import { environment } from "src/environments/environment";
import { CookieService } from "ngx-cookie-service";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { LanguageService } from "./language.service";
import { LeadsmainService } from "./product-module/Leads/leadsmain.service";
// import { ThrowStmt } from '@angular/compiler';

const isSkipLocationChange = environment.isSkipLocationChange;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  @ViewChild("itemElement") firstItemElement: ElementRef | undefined;
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective

  lprFEURL:any=environment.lprFEURL;
  nacFEURL:any=environment.nacFEURL;
  faFEURL:any=environment.faFEURL;
  pprFEURL:any=environment.pprFEURL;
  // Window Width
  windowWidth: number = window.innerWidth;
  menuList: any[];
  navename: any;
  notificationpopup: boolean=false
  @HostListener("window:resize", ["$event"])
  @ViewChild('closeentityreload')closeentityreload;

  onResize(event: any) {
    this.windowWidth = event.target.innerWidth;
    if (this.windowWidth <= 1000) {
      if (!this.sharedService.isSideNav) {
        this.openNav();
      }
    } else {
      if (this.sharedService.isSideNav) {
        this.openNav();
      }
    }
  }

  Url = environment.apiURL;
  isPremise = false;
  sideNavWidth: any = 220;
  showModal: boolean;
  timed: boolean = false;
  idleState = "Not started.";
  CommonTitle = "";
  timedOut = false;
  lastPing?: Date;
  countdown: any;
  countdown_Keypress: any;
  adcolor: any;
  count = 100;
  timeout: any;
  currentUrl: any;
  // isLogged: boolean = true;
  isLoading: boolean = true;
  title = "My First App";
  // Loginname = "";
  MODULES: any[];
  MODULES1: any[];
  TeamMembers = [];
  // MyModuleName = "";
  ionName: any;
  isIonName: boolean;
  // isSideNav: boolean;
  menurlList: Array<any>;
  menuId: number;
  subModuleList: any[];
  titleUrls: string;
  urlTitle: any;
  // transactionList = [];
  // masterList = [];
  defa: any;
  isMasterList = false;
  isTransactionList = false;
  counter = 10;
  apiTimer: any;
  masterUrl: any;
  otpflag = false;
  transactionUrl: any;
  branchViewName: string;
  isbranchView: boolean;
  headerName = "";
  vendorCode: string;
  vendorName: string;
  vendorCode_Name: string;
  premiseCode_Name: string;
  premiseCode: string;
  premiseName: string;
  agreementCode: string;
  landLordViewCode: string;
  occupancyViewCode: string;
  premiseDetailsName: string;
  premiseHeaderTitle: string;
  public currentlyClickedCardIndex: number = 0;
  premisesData: any;
  header_Name: string;
  mobileupdationform: any;
  login_id: any;
  editflag = false;
  // mobileupdation=false;
  @ViewChild("closebutton") closebutton;
  login_code: any;
  mobileid: any;
  CommonSummaryNavigator: string;
  remsshareService: any;
  sample: any;
  hideDooctiDiv: boolean = false;
  screenWidth: any;
  notificationarray: any[]=[]
  intervalId: any;
  timeouts:any[]=[]
  showPopup: boolean=false
  notificationvalue: any[]=[]
  panelOpenState = false;
  shownotifypop=false

  constructor(
    private shareService: SharedService,
    private idle: Idle,
    public cookieService: CookieService,
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private notification: NotificationService,
    public sharedService: SharedService,
    private globalshareservice: SharedService,
    // private shareService: ShareService,
    private routerneew: Router,
    private SpinnerService: NgxSpinnerService,
    private router: Router,
    private location: Location,
    //  private remsshareService: RemsShareService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private languageService: LanguageService,
    private reportservice: LeadsmainService
  ) {
    translate.addLangs(["en", "ta", "hi", "ma", "ka"]);
    // translate.setDefaultLang('en')
    // this.isPremise=this.router.getCurrentNavigation().extras.state.isPremise;
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(1);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(900);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    // idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');

    idle.onTimeout.subscribe(() => {
      this.idleState = "";
      this.timedOut = true;
      //let message="session expired"
      // alert(message)
      this.idle.stop();

      localStorage.removeItem("sessionData");
      this.cookieService.delete("my-key", "/");
      if (this.sharedService.TokenRefreshintervalId) {
        clearInterval(this.sharedService.TokenRefreshintervalId);
        this.sharedService.TokenRefreshintervalId = null;
        // console.log("TokenRefreshintervalId stopped");
      }
      this.sharedService.Loginname = undefined;
      this.sharedService.isLoggedin = false;
      this.showModal = false;
      this.router.navigateByUrl("/login");
    });

    // idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
    idle.onTimeoutWarning.subscribe((countdown) => {
      // this.idleState = 'session expired in ' + countdown + ' seconds!';
      this.CommonTitle = this.sharedService.MyModuleName;
      this.idleState = "(" + countdown + " s)";
      this.countdown_Keypress = countdown;
      if (countdown == 1) {
        this.timed = true;
      }
      if (countdown <= 300) {
        this.adcolor = "red";
      } else {
        this.adcolor = "grey";
      }
      if (countdown === 300) {
        this.dataService.getRefresh().subscribe((result) => {
          // console.log("refreshhhh",result)
        });
      }

      if (countdown === 30) {
        this.showModal = true;
      }
    });

    this.reset();

    const data = this.cookieService.get("my-key");
    const item = localStorage.setItem("sessionData", data);
  } //end of constructor

  //Method to handle key press event
  @HostListener("document:keydown", ["$event"])
  handleKeyPress(event: KeyboardEvent) {
    // Check for the specific key code you are interested in

    if (event.key) {
      if (this.countdown_Keypress > 30) {
        this.idle.watch();
        this.idleState = "Started.";
        this.timedOut = false;
      }
    }
  }

  ngOnDestroy() {
    if (this.sharedService.TokenRefreshintervalId) {
      clearInterval(this.sharedService.TokenRefreshintervalId);
      this.sharedService.TokenRefreshintervalId = null;
      // console.log("TokenRefreshintervalId stopped");
    }
    this.clearTimeouts();
  }

  leadUploadUrl: [];
  ngOnInit() {
    window.addEventListener('message', this.receiveMessage2.bind(this), false);
    // console.log('window',window)
console.log('this.dataService.entitname',this.dataService.entitname)
    this.screenWidth = window.innerWidth;
    // this.navename = 'Home';
    this.hideDoocti = false;

    this.swicthLang("en");
    this.sample = this.sharedService.isLoggedin;
    this.defa = this.shareService.isLoggedin;
    // console.log("this.sharedService.isLoggedin", this.sharedService.isLoggedin);
    this.countdown_Keypress = 0;
    this.mobileupdationform = this.formBuilder.group({
      code: [""],
      name: [""],
      mobile_number: [""],
      otp: [""],
      id: [""],
    });
    this.ReloadForm = this.formBuilder.group({
      entity: [''],
    });
    // this.sharedService.isSideNav = false;
    this.sharedService.sidenavhide$.subscribe((language) => {
      // this.sharedService.isSideNav = false;
      // this.sideNavWidth = 220;
      // console.log("sidenave",language)
      // this.openNav()
      // this.sideNavWidth = 70;
      // this.sharedService.isSideNav = false;
      // this.selectedLanguage = language;
      // this.swicthLang(this.selectedLanguage)
      // Implement logic to reflect language change in this component
    });
    this.sharedService.ionName.subscribe((data) => {
      this.ionName = data;
      this.isIonName = this.ionName === "" ? false : true;
    });

    this.shareService.vendorViewHeaderName.subscribe((result) => {
      let data: any = result;
      this.headerName = "vendorView";
      this.vendorCode = data.code;
      this.vendorName = data.name;
      this.vendorCode_Name = this.vendorCode + "-" + this.vendorName;
      if (this.vendorCode_Name) {
        this.sharedService.MyModuleName = "";
      }
      if (this.vendorCode_Name === "undefined-undefined") {
        this.headerName = "";
      }
    });

    this.shareService.branchView.subscribe((res) => {
      let data: any = res;
      // console.log('dssss',data)

      this.headerName = "branchView";
      this.branchViewName = data.code + "-" + data.name;
      this.isbranchView = this.branchViewName === "" ? false : true;
      if (this.branchViewName === undefined) {
        this.headerName = "";
      }
      if (this.branchViewName === "undefined-undefined") {
        this.headerName = "";
      }
    });
    const item = localStorage.getItem("sessionData");
    // console.log('itemValue',item)

    if (item !== null && item !== '') {
      let itemValue = JSON.parse(item);
      this.sharedService.Loginname = itemValue.name;
      this.sharedService.isLoggedin = true;
      this.sharedService.loginUserId = itemValue.user_id;
      this.sharedService.loginEmpId = itemValue.employee_id;
      this.sharedService.entity_Name = itemValue.entity_name;

      // this.sharedService.ent
      this.getMenuUrl();
      // this.defaultlogin()
    }
    // this.getPremiseData();
    // console.log('welcome dooccti call - 1');
    // if (data.token) {
    //   this.getMenuUrl(this.loginResult.portal_id);
    // }
    // this.modulerefresh();
  }
  entityReloadId:any;
  select_entityName(data){
    let list = data.id
    this.entityReloadId = list
    console.log("entity-id", this.entityReloadId)
  }
  ReloadForm:FormGroup;
  entityList:any;
  entityReload() {
    this.dataService.getEntityReload_List()
      .subscribe((results: any[]) => {
        let datas = results["data"];
        console.log("entyreload-list",datas)
        this.entityList = datas;
      })
  }
  viewDetail_Entityreload(){
    if (this.ReloadForm.value.entity === "") {
      this.notification.error('Please Enter Entity');
      return false;
    }
    this.sharedService.entity_name.next(this.entityReloadId)
    this.formGroupDirective.resetForm();
    // this.closeentityreload.nativeElement.click();
    this.dataService.getEntityReload_update(this.entityReloadId)
      .subscribe((result) => {
        console.log(result)
      if (result.status == "success") {
        this.notification.showSuccess("Updated Successfully")
        // this.router.navigate(["/atma/vendor"], {
        //   skipLocationChange: true
        // })
        // this.router.navigate([this.router.url])
        // window.location.reload();
      } else {
        this.notification.showError(result.description);
      } 
      })
    
    // this.router.navigate(['/verify'], { skipLocationChange: true })
  }
  onClickSwitchIcon(){
    this.ReloadForm.patchValue({
      "entity": ""
    })
    this.entityReload()

  }
  openNav() {
    const navLeft = document.getElementById("navigation-left") as HTMLElement;
    const navMenus = document.querySelector(".navigation-right") as HTMLElement;

    if (this.sharedService.isSideNav) {
      console.log('if')
        navLeft.style.width = "200px";
        navMenus.style.marginLeft = "200px";
        this.sharedService.isSideNav = false;
    } else {
      console.log('else')
        navLeft.style.width = "70px";
        navMenus.style.marginLeft = "70px";
        this.sharedService.isSideNav = true;
    }
}


  receiveMessage2(event) {
    // console.log('start recevemessage')
    if (event.origin !== 'https://crm-app.doocti.com') {
      return;
    }
    // console.log('end recevemessage')
    // console.log('welcome dooccti call - 3')
      var data = event.data;
      // console.log('startmessagessss',data);
      if (data) {
        // console.log('welcome dooccti call - 4')
        this.hideDooctiDiv = true;
        this.hideDoocti = true;
    
      }
      // console.log( this.hideDoocti)
      // console.log('end recevemessage2')
    }
  closeDoocti() {
    this.hideDooctiDiv = false;
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
    // console.log("efwewwefwef", this.screenWidth);
  }
  // ngOnChanges(changes: SimpleChanges): void {
  //   // Check if the SummaryData input has changed
  //   console.log('simple', this.firstItemElement)
  //   if (changes.firstItemElement) {
  //     // Perform actions or logic when SummaryData changes
  //     this.defaultlogin()
  //   }}
  ngAfterViewInit() {
    // Check if there is a first item
    if (this.firstItemElement) {
      // Trigger click on the first item
      this.defaultlogin();
    }
  }
  // ngAfterViewInit() {
  //   // Check if there is a first item
  //   if (this.firstItemElement) {
  //     // Trigger click on the first item
  //     // this.defaultlogin()
  //     console.log('simple', this.firstItemElement)

  //   }
  // }
  defaultlogin() {
    let data = this.sharedService.transactionList;
    this.myModuleFunction(data[0], 0);

    this.transactionUrl = data[0]?.url;
    this.sharedService.MyModuleName = data[0].name;
    this.router.navigateByUrl(this.transactionUrl, {
      skipLocationChange: isSkipLocationChange,
    });
    this.isTransactionList = true;
  }
  mobile_popu() {
    this.otpflag = false;
    const sessionData = localStorage.getItem("sessionData");
    let logindata = JSON.parse(sessionData);
    this.login_code = logindata.code;
    this.getmobilestatus();
  }
  getmobilestatus() {
    this.dataService.getempmobiedata(this.login_code).then((results: any[]) => {
      let datas = results["data"];
      if (datas) {
        this.mobileupdationform
          .get("mobile_number")
          .setValue(datas.mobile_number);
        this.mobileupdationform.get("code").setValue(datas.code);
        this.mobileupdationform.get("name").setValue(datas.full_name);
        this.mobileupdationform.get("id").setValue(datas.id);
        this.editflag = true;
      }
    });
  }

  submitForm() {
    this.mobileupdationform.get("otp").setValue("");
    this.otpflag = false;
    let data = localStorage.getItem("location");
    if (data == "true") {
      this.notification.showWarning(
        "You are trying to login from outside KVB environment.Kindly access the App via KVB environment and update your mobile number in the xxxxxxxxxx for getting the OTP"
      );
      return false;
    }
    if (this.mobileupdationform.value.mobile_number.length == 10) {
      this.count = 35;
      this.timeout = setInterval(() => {
        if (this.count > 0) {
          this.count -= 1;
        } else {
          clearInterval(this.timeout);
        }
      }, 500);
      this.dataService
        .mobiledatapost(this.mobileupdationform.value)
        .subscribe((results) => {
          let datas = results;
          if (results.id) {
            this.otpflag = true;
            this.mobileid = results.id;
            this.notification.showSuccess(
              "Please enter the 8-digit verification code we sent via SMS:(we want to make sure it's you before update "
            );
          } else {
            this.notification.showWarning("failed");
            this.otpflag = false;
          }
        });
    }
  }

  updatemobile() {
    var otpdata = { otp: this.mobileupdationform.value.otp };
    this.dataService
      .employeemobilenomicro(otpdata, this.mobileid)
      .then((data) => {
        if (data["MESSAGE"] == "SUCCESS") {
          this.notification.showSuccess("Success");
          this.mobileupdationform.reset();
          this.otpflag = false;
          this.closebutton.nativeElement.click();
        } else {
          this.notification.showWarning(data["MESSAGE"]);
          this.mobileupdationform.reset();
          this.closebutton.nativeElement.click();
        }
      });
  }

  private getMenuUrl() {
    this.notificationPop()
    this.SpinnerService.show();
    this.dataService.getMenuUrl().subscribe((results: any[]) => {
      this.SpinnerService.hide();
      this.currentUrl = window.location.hash;
      this.currentUrl = this.currentUrl.startsWith("#")
        ? this.currentUrl.substring(1)
        : this.currentUrl;
      let data = results["data"];
      let fristurl = data[0].url;
      this.sharedService.titleUrl = fristurl;
      this.sharedService.menuUrlData = data;
      this.menurlList = this.sharedService.menuUrlData;
      this.titleUrls = this.sharedService.titleUrl;
      //this.router.navigateByUrl(this.sharedService.titleUrl, { skipLocationChange: false });
      // this.menuList = data;
      if (data) {
        this.menuList = data;
      }
      // console.log("this.menuList", this.menuList);
      this.navename = this.menuList[0].name;
      this.sharedService.transactionList = [];
      this.sharedService.masterList = [];
      this.menurlList.forEach((element) => {
        if (element.type === "transaction") {
          this.sharedService.transactionList.push(element);
        } else if (element.type === "master") {
          this.sharedService.masterList.push(element);
        }
      });
      // this.router.navigate([this.sharedService.transactionList[0].url])
      this.currentlyClickedCardIndex = 0;
      this.modulerefresh();
    });
  }

  continue() {
    this.showModal = false;

    this.reset();
    this.dataService.getRefresh().subscribe((result) => {
      this.reset();
    });
  }

  logout() {
    this.showModal = false;
    this.idleState = "";
    this.timedOut = true;
    this.logout1();
    this.idle.stop();
    localStorage.removeItem("sessionData");
    localStorage.removeItem('UserData');
    this.cookieService.delete("my-key", "/");
    // this.isLogged = false;
    // this.Loginname = undefined;
    this.sharedService.Loginname = undefined;
    this.sharedService.isLoggedin = false;
    this.sharedService.MyModuleName = "";
    this.sharedService.Memofrom = "";
    this.headerName = "";
    if (this.sharedService.TokenRefreshintervalId) {
      clearInterval(this.sharedService.TokenRefreshintervalId);
      this.sharedService.TokenRefreshintervalId = null;
      // console.log("TokenRefreshintervalId stopped");
    }
    this.router.navigateByUrl("/login");
  }

  private logout1() {
    this.dataService.logout().subscribe((results: any[]) => {
      let datas = results["data"];
    });
  }

  myModuleFunction(modrow, cardIndex) {
    // console.log(modrow, "modrow");
    this.navename = modrow.name;
    const existingIndex = this.menuList.indexOf(modrow);
    if (existingIndex !== -1) {
      this.menuList.splice(existingIndex, 1);
    }
    this.menuList.splice(6, 0, modrow);
    if (modrow.name === "Custom Module Master") {
      this.sideNavWidth = 70;
      this.sharedService.isSideNav = true;
    }
    this.shareService.submoduledata.next(modrow?.submodule);
    this.isIonName = false;
    this.menuId = modrow?.id;
    this.headerName = "";
    this.premiseHeaderTitle = "";
    this.sharedService.MyModuleName = modrow?.name;
    this.shareService.myModuleUrl.next(modrow?.url);

    // console.log("main module in app comp", modrow?.url);
    this.currentlyClickedCardIndex = cardIndex;

     if (modrow.url === "/memosummary") {
      console.log("call1")
      //this.router.navigate(['ememo', 'memosummary']);
      window.location.href=this.nacFEURL+"ememo/memosummary";
      return true;
    }
    if (modrow.url === "/rems") {
      //this.router.navigate(['rems/rems']);
      window.location.href=this.nacFEURL+"rems/rems";
      return true;
    }
    if (modrow.url === "/rcn") {
      //this.router.navigate(['prpo/rcn']);
      window.location.href=this.nacFEURL+"prpo/rcn";
      return true;
    }
    if (modrow.url === "/bpa") {
      //this.router.navigate(['prpo/bpa']);
      window.location.href=this.nacFEURL+"prpo/bpa";
      return true;
    }
    if (modrow.url === "/pca") {
      //this.router.navigate(['prpo/pca']);
      window.location.href=this.nacFEURL+"prpo/pca";
      return true;
    }
    if (modrow.url === "/pr") {
      //this.router.navigate(['prpo/pr']);
      window.location.href=this.nacFEURL+"prpo/pr";
      return true;
    }
    if (modrow.url === "/po") {
      //this.router.navigate(['prpo/po']);
      window.location.href=this.nacFEURL+"prpo/po";
      return true;
    }
    if (modrow.url === "/grn") {
      //this.router.navigate(['prpo/grn']);
      window.location.href=this.nacFEURL+"prpo/grn";
      return true;
    }
    if (modrow.url === "/procurementmaster") {
      //this.router.navigate(['prpo/procurementmaster']);
      window.location.href=this.nacFEURL+"prpo/procurementmaster";
      return true;
    }
    if (modrow.url === "/vendor") {
      //this.router.navigate(['atma/vendor']);
      window.location.href=this.nacFEURL+"atma/vendor";
      return true;
    }
    if (modrow.url === "/vendormaster") {
      //this.router.navigate(['atma/vendormaster']);
      window.location.href=this.nacFEURL+"atma/vendormaster";
      return true;
    }
    if (modrow.url === "/master") {
      //this.router.navigate(['master/master']);
      window.location.href=this.nacFEURL+"master/master";
      return true;
    }
    if (modrow.url === "/pprreport") {
      //this.router.navigate(['ppr/pprreport']);
      window.location.href=this.pprFEURL+"ppr/pprreport";
      return true;
    }
    if (modrow.url === "/dssreport") {
      //this.router.navigate(['dss/dssreport']);
      window.location.href=this.nacFEURL+"dss/dssreport";
      return true;
    }
    if (modrow.url === "/inwardSummary") {
      //this.router.navigate(['inwardd/inwardSummary']);
      window.location.href=this.nacFEURL+"inwardd/inwardSummary";
      return true;
    }
    if (modrow.url === "/securityguard") {
      //this.router.navigate(['SGmodule/securityguardpayment']);
      window.location.href=this.nacFEURL+"SGmodule/securityguardpayment";
      return true;
    }
    if (modrow.url === "/securityguardmaster") {
      //this.router.navigate(['SGmodule/sgmaster']);
      window.location.href=this.nacFEURL+"SGmodule/sgmaster";
      return true;
    }
    if (modrow.url === "/fa") {
      // this.sharedService.submodulesfa.next(modrow.submodule);
      // this.router.navigate(['fa/fa']);
      window.location.href=this.faFEURL+"fa/fa";
      return true;
    }
    if (modrow.url === "/lpr") {
      this.sharedService.submodulesfa.next(modrow.submodule);
      this.router.navigate(['lpr/lpr']);
      return true;
    }
    if (modrow.url === "/ta_summary") {
      //this.router.navigate(['ta/ta_summary']);
      window.location.href=this.nacFEURL+"ta/ta_summary";
      return true;
    }
    if(modrow.url === "/tamaster"){
     // this.router.navigate(['ta/ta_master']);
     window.location.href=this.nacFEURL+"ta/ta_master";
      return true;
    }
    if (modrow.url === "/documentation") {
      //this.router.navigate(['documentation/documentation']);
      window.location.href=this.nacFEURL+"documentation/documentation";
      return true;
    }
    if (modrow.url === "/los") {
      //this.router.navigate(['dtpc/los']);
      window.location.href=this.nacFEURL+"dtpc/los";
      return true;
    }
    if (modrow.url === "/ecf") {
     // this.router.navigate(['ECF/ecf']);
      this.CommonSummaryNavigator = 'ECF';
      window.location.href=this.nacFEURL+"ECF/ecf";
      return true;
    }
    if (modrow.url === "/ap") {
      //this.router.navigate(['ap/ap']);
      this.CommonSummaryNavigator = 'AP';
      window.location.href=this.nacFEURL+"ap/ap"
      return true;
    }
    if (modrow.url === "/inwardMaster") {
      //this.router.navigate(['inward/inwardMaster']);
      window.location.href=this.nacFEURL+"inward/inwardMaster";
      return true;
    }
    if (modrow.url === "/inward") {
      //this.router.navigate(['inward/inward']);
      window.location.href=this.nacFEURL+"inward/inward";
      return true;
    }
   if (modrow.url === "/jvsummary") {
      // this.router.navigate(['JV/jvsummary']);
      // this.CommonSummaryNavigator = 'JV'
      window.location.href=this.nacFEURL+"JV/jvsummary";
      return true;
   }
  if (modrow.url === "/entrymaster") {
    //this.router.navigate(['entry/entrymaster']);
    window.location.href=this.nacFEURL+"entry/entrymaster";
    return true;
  }
  if (modrow.url === "/report") {
    this.dataService.submodulesreport.next(modrow.submodule);
    //this.router.navigate(['report/report']);
    window.location.href=this.nacFEURL+"report/report";
    return true;
  }
  if (modrow.url === "/fi") {
    // this.sharedService.submodulesfi.next(modrow.submodule)
    // console.log(this.faFEURL + "fi/fi")
    // this.router.navigate(['fi/fi']);
    const getToken = localStorage.getItem('sessionData');
    let tokenValue = JSON.parse(getToken);
    localStorage.setItem("sessionDatas",JSON.stringify(tokenValue))
    window.location.href=this.nacFEURL+"fi/fi"
    return true;
  }
    // this.router.navigate([modrow.url], { skipLocationChange: isSkipLocationChange });
    // this.router
    //   .navigateByUrl("crmreport/reportsmain", { skipLocationChange: true })
    //   .then(() => {
    //     this.router.navigate([modrow?.url]);
    //   });
    // this.router.navigate([modrow.url], { skipLocationChange: isSkipLocationChange });//,
    this.checkIfCardIsClicked(cardIndex);
  }
  myModuleFunctions(modrow, cardIndex) {
    // console.log(modrow, "modrow");
    this.navename = modrow.name;
    if (modrow.name === "Custom Module Master") {
      this.sideNavWidth = 70;
      this.sharedService.isSideNav = true;
    }
    this.shareService.submoduledata.next(modrow?.submodule);
    this.isIonName = false;
    this.menuId = modrow?.id;
    this.headerName = "";
    this.premiseHeaderTitle = "";
    this.sharedService.MyModuleName = modrow?.name;
    this.shareService.myModuleUrl.next(modrow?.url);

    // console.log("main module in app comp", modrow?.url);
    this.currentlyClickedCardIndex = cardIndex;

    // console.log("modrow.url", modrow.url)
    // if (modrow.url === "/memosummary") {
    //   // console.log("call1")
    //   this.router.navigate(['ememo', 'memosummary']);
    //   return true;
    // }
    // if (modrow.url === "/rems") {
    //   this.router.navigate(['rems/rems']);
    //   return true;
    // }
    // if (modrow.url === "/rcn") {
    //   this.router.navigate(['prpo/rcn']);
    //   return true;
    // }
    // if (modrow.url === "/bpa") {
    //   this.router.navigate(['prpo/bpa']);
    //   return true;
    // }
    // if (modrow.url === "/pca") {
    //   this.router.navigate(['prpo/pca']);
    //   return true;
    // }
    // if (modrow.url === "/pr") {
    //   this.router.navigate(['prpo/pr']);
    //   return true;
    // }
    // if (modrow.url === "/po") {
    //   this.router.navigate(['prpo/po']);
    //   return true;
    // }
    // if (modrow.url === "/grn") {
    //   this.router.navigate(['prpo/grn']);
    //   return true;
    // }
    // if (modrow.url === "/procurementmaster") {
    //   this.router.navigate(['prpo/procurementmaster']);
    //   return true;
    // }
    // if (modrow.url === "/vendor") {
    //   this.router.navigate(['atma/vendor']);
    //   return true;
    // } if (modrow.url === "/bre") {
    //   this.router.navigate(['bre/bre']);
    //   return true;
    // }
    // if (modrow.url === "/ap") {
    //   this.router.navigate(['ap/ap']);
    //   this.CommonSummaryNavigator = 'AP'
    //   return true;
    // }
    // if (modrow.url === "/provision") {
    //   this.router.navigate(['provision/provision']);
    //   return true;
    // }
    // if (modrow.url === "/vendormaster") {
    //   this.router.navigate(['atma/vendormaster']);
    //   return true;
    // }
    // if (modrow.url === "/master") {
    //   this.router.navigate(['master/master']);
    //   return true;
    // }
    // if (modrow.url === "/pprreport") {
    //   this.router.navigate(['ppr/pprreport']);
    //   return true;
    // }
    // if (modrow.url === "/inward") {
    //   this.router.navigate(['inward/inward']);
    //   return true;
    // }
    // if (modrow.url === "/securityguard") {
    //   this.router.navigate(['SGmodule/securityguardpayment']);
    //   return true;
    // }
    // if (modrow.url === "/securityguardmaster") {
    //   this.router.navigate(['SGmodule/sgmaster']);
    //   return true;
    // }
    // if (modrow.url === "/fa") {
    //   this.router.navigate(['fa/fa']);
    //   this.sharedService.submodulesfa.next(modrow.submodule)

    //   return true;
    // }
    // if (modrow.url === "/tneb/electricityexpensemaster") {
    //   this.router.navigateByUrl('/tneb/electricityexpensemaster');
    //   this.sharedService.submodulestneb.next(modrow.submodule)

    //   return true;
    // }
    // if (modrow.url === "/tneb/electricityexpense") {
    //   this.router.navigateByUrl('/tneb/electricityexpense');
    //   this.sharedService.submodulestneb.next(modrow.submodule)

    //   return true;
    // }
    // if (modrow.url === "/sms") {
    //   this.router.navigate(['sms/smstransaction']);
    //   this.sharedService.submodulessms.next(modrow.submodule)

    //   return true;
    // }
    // if (modrow.url === "/sms/smsmaster") {
    //   this.sharedService.submodulessmsmaster.next(modrow.submodule);
    //   this.router.navigate(['sms/smsmaster']);

    //   return true;
    // }
    // if (modrow.url === "/ta_summary") {
    //   this.router.navigate(['ta/ta_summary']);
    //   return true;
    // }

    // if (modrow.url === "/rmu_summary") {
    //   this.router.navigate(['rmu/rmu_summary']);
    //   return true;
    // }
    // if (modrow.url === "/frs_summary") {
    //   this.router.navigate(['frs/frstransaction']);
    //   return true;
    // }
    // if (modrow.url === "/ProofingMaster") {
    //   this.router.navigate(['proofing/ProofingMaster']);
    //   return true;
    // }
    // if (modrow.url === "/ProofingMap") {
    //   this.router.navigate(['proofing/ProofingMap']);
    //   return true;
    // }
    // if (modrow.url === "/ProofingUpload") {
    //   this.router.navigate(['proofing/ProofingUpload']);
    //   return true
    // }
    // if (modrow.url === "/tamaster") {
    //   this.router.navigate(['ta/ta_master']);
    //   return true;
    // }
    // if (modrow.url === "/documentation") {
    //   this.router.navigate(['documentation/documentation']);
    //   return true;
    // }
    // if (modrow.url === "/los") {
    //   this.router.navigate(['dtpc/los']);
    //   return true;
    // }
    // if (modrow.url === "/ecf") {
    //   this.router.navigate(['ECF/ecf']);
    //   this.CommonSummaryNavigator = 'ECF'
    //   return true;
    // }
    // if (modrow.url === "/inwardMaster") {
    //   this.router.navigate(['inward/inwardMaster']);
    //   return true;
    // }
    // if (modrow.url === "/inwarddocumentsummary") {
    //   this.router.navigate(['inward/inwarddocumentsummary']);
    //   return true
    // }

    // if (modrow.url === "/entry") {
    //   this.router.navigate(['entry/entry']);
    //   return true;
    // }
    // if (modrow.url === "/vehiclefleet") {
    //   this.router.navigate(['vfm/vfm_summary']);
    //   return true;
    // }
    // if (modrow.url === "/interintegrity") {
    //   this.router.navigate(['interintegrity/intertrans']);
    //   return true;
    // }
    // if (modrow.url === "/jvsummary") {
    //   this.router.navigate(['JV/jvsummary']);
    //   this.CommonSummaryNavigator = 'JV'
    //   return true;
    // }
    // if (modrow.url === "/jwsummary") {
    //   this.router.navigate(['JW/jwsummary']);
    //   this.CommonSummaryNavigator = 'JW'
    //   return true;
    // }
    // //  console.log("modrow.url ",modrow.url)
    // if (modrow.url === "/ecfapsummary") {
    //   this.router.navigate(['ECFAP/ecfapsummary']);
    //   this.CommonSummaryNavigator = 'ECFAP'
    //   return true;
    // }
    // if (modrow.url === "/ecfapmakersummary") {
    //   this.router.navigate(['ECFAP/ecfapmakersummary']);
    //   this.CommonSummaryNavigator = 'ECFAP'
    //   return true;
    // }
    // if (modrow.url === "/drs") {
    //   this.router.navigate(['drs/reportbuilder']);
    //   return true;
    // }
    // if (modrow.url === "/crmmain") {
    //   this.router.navigateByUrl('/login', { skipLocationChange: true }).then(() => {
    //     this.router.navigate(['crm/mainpage']);
    //             });

    //   return true;
    // }
    // if (modrow.url === "/leads") {
    //   this.router.navigateByUrl('/login', { skipLocationChange: true }).then(() => {
    //     this.router.navigate(['crm/mainpages']);
    //                   });

    //   return true;
    // }
    // if (modrow.url === "/campaign") {
    //   this.router.navigateByUrl('/login', { skipLocationChange: true }).then(() => {
    //     this.router.navigate(['crm/mainpagess']);
    //                   });

    //   return true;
    // }

    // if (modrow.url === "/dashboard") {
    //   this.router.navigateByUrl('/login', { skipLocationChange: true }).then(() => {
    //     this.router.navigate(['vow/dashboard']);
    //                         });

    //   return true;
    // }
    // if (modrow.url === "/vow") {
    //   this.router.navigateByUrl('/login', { skipLocationChange: true }).then(() => {
    //     this.router.navigate(['lead/vow_summary']);
    //   });
    //   return true;
    // }
    // if (modrow.url === "/crmreport") {
    //   this.router.navigateByUrl('/login', { skipLocationChange: true }).then(() => {
    //     this.router.navigate(['crmreport/reportsmain']);
    //   });
    //   return true;
    // }
    // if (modrow.url === "/crmmaster") {
    //   this.router.navigateByUrl('/login', { skipLocationChange: true }).then(() => {
    //     this.router.navigate(['crm/crmmaster']);
    //   });
    //   return true;
    // }
    // if (modrow.url === "/employeetask") {
    //   this.router.navigateByUrl('/login', { skipLocationChange: true }).then(() => {
    //     this.router.navigate(['crm/employeetask']);
    //   });
    //   return true;
    // }

    // if (modrow.url === "/product") {
    //   this.router.navigateByUrl('/login', { skipLocationChange: true }).then(() => {
    //     this.router.navigate(['crm/crm/summary']);
    //   });
    //   // this.sharedService.submodulesreport.next(modrow.submodule)
    //   return true;
    // }

    this.router
      .navigateByUrl("crmreport/reportsmain", { skipLocationChange: true })
      .then(() => {
        this.router.navigate([modrow?.url]);
      });
    // this.router.navigate([modrow.url], { skipLocationChange: isSkipLocationChange });//,
    this.checkIfCardIsClicked(cardIndex);
  }
  onClick(menu: any, index: number) {
    // console.log("Clicked menu:", menu);
    // console.log("Clicked index:", index);
    this.currentlyClickedCardIndex = index; // Update currentlyClickedCardIndex
  }
  public checkIfCardIsClicked(cardIndex: number): boolean {
    return cardIndex === this.currentlyClickedCardIndex;
  }

  reset() {
    this.idle.watch();
    this.idleState = "Started.";
    this.timedOut = false;
  }

  backNavigation() {
    this.isIonName = false;
    this.sharedService.ionName.next("");
    this.router.navigate(["/ememo/memosummary"], {
      skipLocationChange: isSkipLocationChange,
    });
  }

  // openNav() {
  //   if (this.sharedService.isSideNav) {
  //     this.sideNavWidth = 220;
  //     this.sharedService.isSideNav = false;
  //     // document.getElementById("mySidenav").style.width = "240px";
  //     // document.getElementById("main").style.marginLeft = "12rem";
  //   } else {
  //     this.sideNavWidth = 70;
  //     this.sharedService.isSideNav = true;
  //     // document.getElementById("mySidenav").style.width = "70px";
  //     // document.getElementById("main").style.marginLeft = "40px";
  //   }
  // }
  navurl: any = [];
  masterData() {
    let data = this.sharedService.masterList;
    // let url=this.shareService.myModuleUrl.value
    // console.log(url)
    this.masterUrl = data[0]?.url;
    // let masterUrl = data[0].submodule[0].url
    //  this.navurl=this.masterUrl+masterUrl
    // this.shareService.submoduledata.next(data[0]?.submodule)
    // this.sharedService.MyModuleName = data[0].name;
    // this.router.navigateByUrl(this.masterUrl, { skipLocationChange: isSkipLocationChange });
    // this.router.navigateByUrl(this.navurl, { replaceUrl:false });
    this.isMasterList = true;
    this.isTransactionList = false;
    this.headerName = "";
    this.myModuleFunction(data[0], 0);
    //  this.ngOnInit()
  }
  homes() {
    let data = this.sharedService.transactionList;
    this.transactionUrl = data[0]?.url;
    this.sharedService.MyModuleName = data[0].name;
    this.router.navigateByUrl(this.transactionUrl, {
      skipLocationChange: isSkipLocationChange,
    });
    this.isTransactionList = true;
    this.isMasterList = false;
    this.headerName = "";
  }

  backBranchView() {
    this.router.navigate(["/atma/vendorView"], {
      skipLocationChange: isSkipLocationChange,
    });
  }

  backVendor() {
    let vendorName = "Vendor";
    this.sharedService.MyModuleName = vendorName;
    this.headerName = "";
    this.router.navigate(["/atma/vendor"], {
      skipLocationChange: isSkipLocationChange,
    });
  }
  LOS() {
    this.router.navigate(["/los"], { skipLocationChange: true });
  }

  backpremise() {
    this.premisesData.forEach((element) => {
      this.header_Name = element.headerName;
    });
    if (this.premisesData) {
      let index = this.premisesData.length - 1;
      let data = this.premisesData[index];
      this.router.navigate([data.routerUrl], {
        skipLocationChange: isSkipLocationChange,
      });
      this.sharedService.MyModuleName = this.header_Name;
      this.headerName = "";
    }
  }

  reports() {
    this.router.navigate(["/reports"], {
      skipLocationChange: isSkipLocationChange,
    });
  }

  hideDoocti: boolean;

  callMini() {
    this.hideDoocti = !this.hideDoocti;
    // console.log(this.hideDoocti)
  }
  // getPremiseData() {
  //   this.remsshareService.premiseBackNavigation.subscribe(result => {
  //     if (result != null) {
  //       this.premisesData = result.data
  //       let index = this.premisesData.length - 1
  //       let data = this.premisesData[index]
  //       this.headerName = 'REMS';
  //       this.premiseCode = data.code;
  //       this.premiseName = data.name;
  //       if (data.title == BackNavigationData.premiseView) {
  //         this.premiseCode_Name = this.premiseCode + " (" + this.premiseName + ")";
  //       } else if (data.title == BackNavigationData.agreementView) {
  //         this.premiseCode_Name = this.premiseCode;
  //       } else if (data.title == BackNavigationData.landLordView) {
  //         this.premiseCode_Name = this.premiseCode_Name + " / " + this.premiseName;
  //       } else if (data.title == BackNavigationData.occupancyView) {
  //         this.premiseCode_Name = this.premiseCode_Name + " / " + this.premiseCode;
  //       } else if (data.title == BackNavigationData.premiseDetailsView) {
  //         this.premiseCode_Name = this.premiseCode_Name + " / " + this.premiseName;
  //       } else if (data.title == BackNavigationData.premisesIdentificationView) {
  //         this.premiseCode_Name = this.premiseCode + "(" + this.premiseName + ")";
  //       } else if (data.title == BackNavigationData.premisesDocInfoView) {
  //         this.premiseCode_Name = this.premiseName;
  //       } else if (data.title == BackNavigationData.scheduleView) {
  //         this.premiseCode_Name = this.premiseCode;
  //       } else if (data == "") {
  //         this.sharedService.MyModuleName = "REMS"
  //       }
  //     }
  //   })
  // }
  Submoduledata: any = [];
  Firstsubmodule: any;
  leadsObject: any = {};
  private submoduleDataSubscription: Subscription;
  submoduleData: any;
  extractedPart: any;
  urlIndex3: any;
  extractedPart2: any;
  modulerefresh() {
    // console.log("current url", this.currentUrl);
    this.shareService.reloadUrl2.next("");

    // console.log("Original URL:", this.currentUrl);
    const parts = this.currentUrl.split("/").slice(1, 3);
    // console.log("split parts1", parts);
    const parts2 = this.currentUrl.split("/").slice(3, 4);
    // console.log("split parts2", parts2);

    let urlIndex1 = parts[0];
    let urlIndex2 = parts[1];
    if (parts2.length !== 0) {
      let urlIndex3 = parts2[0];

      // console.log("ind 1 and 2", urlIndex1, urlIndex2);
      this.extractedPart = urlIndex1 + "/" + urlIndex2;
      // console.log("extract 1", this.extractedPart);

      this.shareService.reloadUrl.next(this.extractedPart);
      this.extractedPart2 = "/" + urlIndex3;
      // console.log("extract..", this.extractedPart2);
      this.shareService.reloadUrl2.next(this.extractedPart2);
    } else {
      this.shareService.reloadUrl2.next("");
    }
    // console.log("url splice", this.currentUrl);
    this.currentUrl = this.currentUrl.startsWith("#")
      ? this.currentUrl.substring(2)
      : this.currentUrl;
    if (
      this.currentUrl != "" &&
      this.currentUrl != undefined &&
      this.currentUrl != null
    ) {
      let moduleurl = "";
      // console.log(
      //   "lead data url]]]]]]]]]]",
      //   this.sharedService.urlModule.value
      // );

      if (this.currentUrl == "/login") {
        this.router.navigate([this.sharedService.transactionList[0].url]);
        // this.sharedService.transactionList = [];
        // this.sharedService.masterList = [];
        // this.sharedService.isLoggedin=false
        // this.logout()
      } else {
        moduleurl = this.extractedPart;
      }
      for (const [index, data] of this.sharedService.menuUrlData.entries()) {
        if (moduleurl === data.url) {
          if (data.type === "transaction") {
            this.isMasterList = false;
            this.isTransactionList = true;
          } else if (data.type === "master") {
            this.isMasterList = true;
            this.isTransactionList = false;
          }
          this.currentlyClickedCardIndex = index;
          this.shareService.submoduledata.next(data.submodule);
          this.router
            .navigateByUrl("/crm", { skipLocationChange: true })
            .then(() => {
              this.myModuleFunction(data, this.currentlyClickedCardIndex);
            });

          return true;
        }
      }
    }
  }
  moduleclick(data, index) {
    this.shareService.reloadUrl2.next("");
    this.shareService.reloadUrl.next("");
    this.myModuleFunction(data, index);
  }

  // swicthLang(lang:string){
  //   this.translate.use(lang)
  //   this.languageService.setLanguage(lang);
  //     }
  swicthLang(lang: string) {
    // console.log("lang", lang);
    this.languageService.setLanguage(lang);
    // setnav
    this.translate.use(lang);
  }

  sidevnavhide() {
    this.sharedService.isSideNav = false;
  }

  notificationPop(): void {
    this.reportservice.notificationtiming().subscribe(results => {
      this.notificationvalue=results['data']
      for (let data of this.notificationvalue) {
        this.notificationarray.push(data.period);
      }
      this.scheduleNotifications();
    });
  }

  scheduleNotifications(): void {
    const currentTime = Date.now();
    const localTimeMilliseconds = new Date(currentTime).getTime();
    
    // console.log(localTimeMilliseconds);

    this.notificationarray.forEach(targetTime => {
      const timeDiff = targetTime - localTimeMilliseconds;
      if (timeDiff > 0) {
        const timeoutId = setTimeout(() => {
          // this.showNotification();
          this.showPopup = true;
          // this.panelOpenState = true;
        }, timeDiff);
        this.timeouts.push(timeoutId);
      }
    });
  }

  showNotification(): void {
    ($('#warningModal') as any).modal('show');
    // console.log('hello')
  }

  clearTimeouts(): void {
    this.timeouts.forEach(timeoutId => clearTimeout(timeoutId));
    this.timeouts = [];
  }
  closethecard(){
    this.shownotifypop=false
  }
  routetotask(data){
    // console.log(data)
    if(data.module_type===1){
      // this.router.navigate(["crm/mainpages/leads_view"], { queryParams: { id: data.id,ref_type:data.ref_type.id } });
      this.router.navigate(["crm/mainpages/leads_view"], { queryParams: { id: data.module_id,ref_type:data.module_type,mapping:data.mapping_id } });
    }
    else if(data.module_type===3){
      this.router.navigate(["crm/accounts/accountview"], { queryParams: { id: data.module_id,ref_type:data.module_type,mapping:data.mapping_id }});
    }
    else if(data.module_type===2){
      this.router.navigate(["crm/contacts/editcontact"], { queryParams: { id: data.module_id,ref_type:data.module_type,mapping:data.mapping_id } });
    }
    else if(data.module_type===7){
      this.router.navigate(["crm/customer/customerview"], { queryParams: { id: data.module_id,ref_type:data.module_type,mapping:data.mapping_id }});
    }
  }
  closePanel(event: Event) {
    event.stopPropagation(); // Prevent the panel from toggling
    this.panelOpenState = false;
    this.showPopup = false;
  }
  getnotoficationpop(){
    // if(this.notificationvalue.length===0){
    //   this.shownotifypop=false
    // }
    // else{
      this.shownotifypop=true;
      ($('#warningModal') as any).modal('show');
    // }

  }
  notifications(){
   this.notificationpopup=!this.notificationpopup
  }
  // closenotification(){
  //   this.notificationpopup=false 
  // }
  closereminder(){
    this.showPopup=false
  }
}

export enum BackNavigationData {
  agreementView = "AgreementView",
  premiseView = "PremiseView",
  landLordView = "LandLordView",
  occupancyView = "OccupancyView",
  premiseDetailsView = "PremiseDetailsView",
  premisesIdentificationView = "PremisesIdentificationView",
  premisesDocInfoView = "PremisesDocInfoView",
  scheduleView = "ScheduleView",
}
