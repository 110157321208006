import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private selectedLanguageSubject: BehaviorSubject<string>;
  selectedLanguage$: Observable<string>;
  constructor() {
    // Initialize the selected language subject with default language
    this.selectedLanguageSubject = new BehaviorSubject<string>('en');
    this.selectedLanguage$ = this.selectedLanguageSubject.asObservable();
  }
  // Method to set the selected language
  setLanguage(language: string): void {
    console.log("language",language)
    this.selectedLanguageSubject.next(language);
  }
  // Method to get the currently selected language
  // getSelectedLanguage(): string {
  //   return this.selectedLanguageSubject.getValue();
  // }
}