<section class="container-login">
  <section class="login-main">
    <div style="height: 100%">
      <img
        src="./assets/images/NorthernArc.png"
        style="width: 100%; height: 100%"
      />
      <!-- <div
        id="carouselExampleAutoplaying"
        class="carousel slide"
        data-bs-ride="carousel"
        style="height: 100%"
      >
        <div class="carousel-inner" style="height: 100%">
          <div class="carousel-item active" style="height: 100%">
            <div class="carousel-div carousel-div-1">
              <div class="carousel-overlay"></div>
              <div class="carousel-content">
                <h2>Leads</h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Optio, temporibus tenetur. Laudantium debitis nihil
                  repellendus cumque eos, nulla illo doloribus.
                </p>
              </div>
            </div>
          </div>
          <div class="carousel-item" style="height: 100%">
            <div class="carousel-div carousel-div-2">
              <div class="carousel-overlay"></div>
              <div class="carousel-content">
                <h2>Accounts</h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Optio, temporibus tenetur. Laudantium debitis nihil
                  repellendus cumque eos, nulla illo doloribus.
                </p>
              </div>
            </div>
          </div>
          <div class="carousel-item" style="height: 100%">
            <div class="carousel-div carousel-div-3">
              <div class="carousel-overlay"></div>
              <div class="carousel-content">
                <h2>Tasks</h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Optio, temporibus tenetur. Laudantium debitis nihil
                  repellendus cumque eos, nulla illo doloribus.
                </p>
              </div>
            </div>
          </div>
          <div class="carousel-item" style="height: 100%">
            <div class="carousel-div carousel-div-4">
              <div class="carousel-overlay"></div>
              <div class="carousel-content">
                <h2>Customer</h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Optio, temporibus tenetur. Laudantium debitis nihil
                  repellendus cumque eos, nulla illo doloribus.
                </p>
              </div>
            </div>
          </div>
          <div class="carousel-item" style="height: 100%">
            <div class="carousel-div carousel-div-5">
              <div class="carousel-overlay"></div>
              <div class="carousel-content">
                <h2>Deals</h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Optio, temporibus tenetur. Laudantium debitis nihil
                  repellendus cumque eos, nulla illo doloribus.
                </p>
              </div>
            </div>
          </div>
          <div class="carousel-item" style="height: 100%">
            <div class="carousel-div carousel-div-6">
              <div class="carousel-overlay"></div>
              <div class="carousel-content">
                <h2>Campaign</h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Optio, temporibus tenetur. Laudantium debitis nihil
                  repellendus cumque eos, nulla illo doloribus.
                </p>
              </div>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div> -->
    </div>

    <div class="login-container">
      <div *ngIf="!mobile_flag" class="login-subContainer">
        <!-- <div>
          <img src="./assets/images/logo1.png" alt="KVB" class="login1" />
        </div> -->
        <div class="login-logo mb-4">
          <!-- <img src="./assets/images/vsolv_logo.png" alt="KVB" class="login1" /> -->
          <!-- <h2>Vsolv Engineering <br />Pvt Ltd.</h2> -->
        </div>
        <span *ngIf="Url === 'https://e-memo-be.kvbank.in/'">
          <h5 class="card-title text-center">Sign In</h5>
        </span>
        <span *ngIf="Url === 'https://emc-memo-be-uat.kvbank.in/'">
          <h5 class="card-title text-center">Sign In - MEMO UAT</h5>
        </span>
        <span *ngIf="Url === 'http://143.110.244.51:8000/'">
          <h6 class="card-title text-center">Sign In- UAT</h6>
        </span>
        <span *ngIf="Url === 'https://emc-memo-be-sit.kvbank.in/'">
          <h5 class="card-title text-center">Sign In - MEMO SIT</h5>
        </span>
        <div *ngIf="vdetail !== true">
          <form
            style="
              font-family: Montserrat;
              display: flex;
              flex-direction: column;
              margin-bottom: 100px;
            "
            [formGroup]="logoForm"
          >
            <h2
              style="
                font-weight: bold;
                font-family: Montserrat;
                margin-bottom: 10px;
              "
            >
              Select Entity
            </h2>
            <mat-form-field
              appearance="fill"
              style="width: 250px; font-family: Montserrat; height: 80px"
            >
              <mat-label>Choose Entity</mat-label>
              <mat-select formControlName="entity">
                <mat-option
                  *ngFor="let list of entityList"
                  (click)="select_entityName(list)"
                  [value]="list.id"
                  >{{ list.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div>
              <button
                mat-raised-button
                style="background-color: #21cbe5; width: 150px; color: white"
                (click)="viewDetail()"
                type="button"
              >
                View Details
              </button>
            </div>
          </form>
        </div>
        <div *ngIf="vdetail === true">
          <div>
            <img
              src="./assets/images/Northern Arc - Logo 1.jpg"
              alt="NAC"
              style="max-height: 60px; margin-bottom: 20px"
              sty
            />
          </div>
          <!-- <span *ngIf="Url==='https://e-memo-be.NACank.in/'">
              <h5 class="card-title text-center">Sign In</h5>
            </span>
            <span *ngIf="Url==='https://emc-memo-be-uat.NACank.in/'">
              <h5 class="card-title text-center">Sign In - MEMO UAT</h5>
            </span> -->
          <!-- <span *ngIf="Url==='http://139.59.32.22:8185/'">
              <h6 class="card-title text-center">Sign In- UAT</h6> 
            </span>  -->

          <form
            [formGroup]="loginForm"
            style="
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              gap: 10px;
              margin-bottom: 100px;
            "
          >
            <input
              type="text"
              style="outline: none; width: 300px"
              formControlName="username"
            />

            <input
              formControlName="password"
              type="password"
              style="outline: none; width: 300px; margin-top: 10px"
            />

            <button class="button-name" (click)="login()">Sign in</button>
          </form>
        </div>
        <!-- <form [formGroup]="loginForm" (ngSubmit)="login()" class="login-form">
          <mat-form-field appearance="outline">
            <mat-label>User Name</mat-label>
            <input
              type="text"
              id="username"
              autocomplete="off"
              name="username"
              placeholder="username"
              formControlName="username"
              matInput
            />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input
              type="password"
              id="password"
              autocomplete="off"
              name="login"
              formControlName="password"
              placeholder="password"
              matInput
            />
          </mat-form-field>

          
          <input
            type="submit"
            style="background-color: #21cbe5;border: none;padding: 4px;border-radius: 4px;"
            value="Login"
          />
        </form> -->
      </div>

      <div *ngIf="mobile_flag">
        <h6 class="card-title text-center" *ngIf="otp_flag">
          Please verify your mobile number
        </h6>
        <form
          [formGroup]="mobile_form"
          class="login-container"
          (ngSubmit)="mobilelogin()"
        >
          <div *ngIf="otp_flag" class="form-label-group">
            Mobile Number
            <input
              type="hidden"
              id="mobile"
              class="form-control"
              autocomplete="off"
              name="Mobile "
              placeholder="Number"
              formControlName="mobile_number"
              [readonly]="true"
            />
            <input
              id="mobile"
              class="form-control"
              autocomplete="off"
              name="Mobile "
              placeholder="Number"
              formControlName="mobile_num"
              [readonly]="true"
            />

            <!-- <p>*******{{mobile_num}}</p> -->
          </div>
          <p *ngIf="otp_flag">
            Please enter the 8-digit verification <br />
            code we sent via SMS:(we want to make sure it's you before Login)
          </p>
          <div *ngIf="otp_flag" class="form-label-group">
            OTP
            <input
              type="password"
              id="password"
              class="form-control"
              autocomplete="off"
              name="login"
              formControlName="otp"
              placeholder=""
            />
          </div>
          <p>Did not Recieve in {{ count }}s?</p>
          <p
            *ngIf="count == 0"
            style="text-align: right; color: blue"
            (click)="gen_otp()"
          >
            <a>Resend OTP</a>
          </p>
          <!-- <input *ngIf="!otp_flag" type="button" class="btn btn-lg btn-primary btn-block text-uppercase"
              (click)="gen_otp()" value="SEND OTP"> -->
          <!-- <input *ngIf="otp_flag" type="button" class="btn btn-sm btn-primary btn-block text-uppercase"
              (click)="gen_otp()" value="RESEND OTP"> -->
          <input
            *ngIf="otp_flag"
            type="submit"
            class="btn btn-lg btn-primary btn-block text-uppercase"
            value="verify"
          />
        </form>
      </div>
      <!-- <p class="mb-2">V 4.12.01</p> -->
    </div>
  </section>

  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>
</section>

